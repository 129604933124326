<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div style="width: 100%;margin-top:10px">
          <el-form-item label="公告标题" prop="notice_title">
            <el-input v-model.trim="params.notice_title" placeholder="请输入公告标题" clearable></el-input>
          </el-form-item>
          <el-form-item label="发布时间" class="col-auto">
            <el-date-picker style="width: 250px" v-model="order_time_range" type="datetimerange" align="center"
              size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" clearable></el-date-picker>
          </el-form-item>

          <el-form-item class="col-auto">
            <el-button @click.prevent="searchEvent" type="primary" size="small" native-type="submit">搜索</el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="公告标题">
          <template slot-scope="scope">{{ scope.row.notice_title }}</template>
        </el-table-column>
        <el-table-column label="发布时间" width="320">
          <template slot-scope="{row}">{{ row.published_time | unixToDate }}</template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import { enterpriseNotice, } from '@/api/info';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
export default {
  name: 'noticeIdentify',
  components: { EnTableLayout, XDialog },
  data () {
    return {
      editVisible: false,//审核页
      order_time_range: [],
      loadingtitle: '',//弹窗标题
      /** 列表loading状态 */
      loading: false,
      txInfo: {//审核表单内容
        notice_title: '',
        publish_immediately: 1,
        published_time: '',
      },
      txRules: {//审核表单验证
        notice_title: [{
          required: true,
          message: '公告内容不得为空',
          trigger: 'blur'
        }],
        publish_immediately: [{
          required: true,
          trigger: 'change'
        }],
        published_time: [{
          required: true,
          message: '发布时间不得为空',
          trigger: 'change'
        }],
      },
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        certification_status: ''
      },
      /** 列表数据 */
      tableData: {},
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
    };
  },
  filters: {
    /**发布状态 */
    account_type_filter: value => {
      switch (value) {
        case 0:
          return '未发布';
        case 1:
          return '已发布';
      }
    }
  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    /** 搜索事件触发 */
    searchEvent () {
      if (//格式化时间选择器
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.start_published_time =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.end_published_time =
            new Date(this.order_time_range[1]).getTime() / 1000;
        } else {
          this.params.start_published_time =
            this.order_time_range[0].getTime() / 1000;
          this.params.end_published_time =
            this.order_time_range[1].getTime() / 1000;
        }
      } else {
        delete this.params.start_published_time
        delete this.params.end_published_time
      }
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    GET_AccountList () {
      this.loading = true;
      let params = this.params;
      enterpriseNotice(params).then(response => {
        this.loading = false;
        this.tableData = response;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}
</style>
